import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { DropdownMenu, DropdownItem, Rate, Button, Overlay, Toast } from 'vant';
import { Getter, Action } from 'vuex-class';
import { Letters, OSSPOSTER, USERID, USERUUID, USERNAME } from '@/constants/common';
import { dispatchAppFeature, getPlatform, substrMixinStr, getMixinStrLens, gupaoEduAuth } from '@/utils/util';
import * as Share from '@/utils/share';
import RecommandList from '@/components/list.vue';
import cookies from 'js-cookie';
let AnalyzeVue = class AnalyzeVue extends Vue {
  constructor() {
    super(...arguments);
    this.cur = 1;
    // 显示答题卡
    this.showCard = false;
    // 答案索引
    this.answerChar = Letters;
    // 显示分享按钮
    this.showShare = false;
    // 显示操作按钮
    this.isShare = true;
    this.loading = false;
    this.finished = false;
    this.pageIndex = 0;
    this.pageSize = 10;
    this.request = false;
    this.isPc = false;
    // 当前显示的题目解析详情
    this.curr = {
      quizId: 0,
      quizType: 0,
      question: '',
      quizDifficulty: 0,
      quizContent: '',
      quizChoiceType: 0,
      realQuestion: '',
      optionList: [],
      userAnswer: ''
    };
    // 图片预览
    this.preview = {
      show: false,
      path: ''
    };
  }
  get progressTitle() {
    return `答题卡：${this.cur}/${this.lens}`;
  }
  // 返回当前题目类型对应的classname
  get questionTypeClassName() {
    return ['radio', 'check', 'qa'][this.curr.quizChoiceType - 1] || '';
  }
  // 返回当前题目类型的标签名
  get questionTagText() {
    return ['单选题', '多选题', '简答题'][this.curr.quizChoiceType - 1] || '';
  }
  // 返回用户作答说明
  get userAnswerText() {
    const {
      rText,
      uText,
      quizType,
      userAnswer
    } = this.curr || {};
    const myAnswer = uText || userAnswer;
    if (quizType !== 1) {
      return myAnswer || '未作答';
    }
    if (myAnswer) {
      return `${myAnswer}（${rText === myAnswer ? '正确' : '错误'}）`;
    }
    return '未作答';
  }
  get isError() {
    const myAnswer = this.curr.uText || this.curr.userAnswer;
    return this.curr.rText !== myAnswer;
  }
  // 展示类型 : 1 错题解析，2 全部解析， 3：错题集
  get type() {
    return this.$route.params.type;
  }
  get isPC() {
    return getPlatform().isPC;
  }
  async created() {
    const loading = this.showLoading();
    this.isPc = getPlatform().isPC;
    const {
      id,
      type
    } = this.$route.params; // type: 1 错题解析，2 全部解析， 3：错题集
    const {
      share,
      idx
    } = this.$route.query;
    this.showShare = Share.show();
    this.isShare = !!share;
    // 错题集功能
    const params = {
      id
    };
    if (type === '1') {
      params.isRight = 0;
    }
    const r = type === '3' ? await this.getErrorRecordListApi(id) : await this.getAnalyzeList(params);
    if (this.data.title) document.title = this.data.title;
    loading.close();
    if (r.code === 0) {
      if (idx) {
        // 加载指定索引处的试题
        this.onTapSwitch(~~idx);
      } else {
        // 默认加载第一题
        await this.switchQuestion();
      }
      // 加载推荐考卷
      if (share) {
        this.onLoadList();
      }
    } else {
      var _r$msg;
      if (r.code === 1001 || ((_r$msg = r.msg) === null || _r$msg === void 0 ? void 0 : _r$msg.indexOf('未登录')) > -1) {
        gupaoEduAuth(this.$route.fullPath);
      } else {
        this.$toast({
          message: r.msg || '数据请求失败'
        });
      }
    }
  }
  // 加载推荐列表
  async onLoadList() {
    if (this.request || this.finished) {
      return;
    }
    this.request = true;
    this.loading = true;
    // 查询推荐试卷
    const r = await this.getRecommandPaper({
      pageIndex: ++this.pageIndex,
      pageSize: this.pageSize
    });
    this.request = false;
    this.loading = false;
    if (r.code === 0) {
      var _r$data;
      // 判断是否已经全部加载完毕
      if (this.pageIndex * this.pageSize >= ((r === null || r === void 0 || (_r$data = r.data) === null || _r$data === void 0 ? void 0 : _r$data.resultCount) || 0)) {
        this.finished = true;
      }
    } else {
      this.finished = true;
      Toast(r.msg || '推荐试卷获取失败');
    }
    this.$nextTick(() => document.body.click());
  }
  showLoading() {
    return this.$toast.loading({
      forbidClick: true,
      duration: 0
    });
  }
  // 更新小程序分享信息
  async updateShareInfo() {
    if (getPlatform().isMiniprogram) {
      const username = cookies.get(USERNAME);
      const poster = await Share.createPoster({
        'text': `${username}邀请你码上测`,
        'accessKey': '1HYtxzl7OeSB6T0c',
        'secretKey': 'PgdEyOlAHNL5Z6RF',
        'posterId': 612
      });
      Share.miniProgram({
        imageUrl: poster,
        title: this.curr.quizContent,
        path: `pages/index/index?path=${encodeURIComponent(`${this.$route.fullPath}?share=1`)}`
      });
    }
  }
  // 切换显示的题目
  async switchQuestion() {
    const {
      type
    } = this.$route.params; // type: 1 错题解析，2 全部解析， 3：错题集
    // 获取指定题目
    const quizId = this.list[this.cur - 1].quizId;
    const id = this.$route.params.id;
    let r;
    if (type === '3') {
      const item = this.list[this.cur - 1];
      r = {
        code: 0,
        data: item
      };
    } else {
      const loading = this.showLoading();
      r = await this.getAnalyzeByQuestion({
        id,
        quizId
      });
      loading.close();
    }
    if (r.code === 0) {
      const data = r.data;
      const {
        optionList = []
      } = data || {};
      const lens = optionList.length;
      data.content = data.answerExplanation || data.content || '暂无解析';
      data.rText = (data.realQuestion || '').replace(/,/g, '').split('').join(',');
      data.uText = data.quizType === 1 ? (data.question || '').replace(/,/g, '').split('').join(',') : data.question || '';
      data.checked = [...new Array(lens)].map((n, i) => data.realQuestion.split('').includes(Letters[i]));
      this.curr = data;
    } else {
      this.$toast({
        message: r.msg || '解析信息获取失败'
      });
    }
    // 更新分享信息
    this.updateShareInfo();
  }
  // 点击预览图片
  onTapPreview(path) {
    this.preview = {
      show: true,
      path
    };
  }
  onTapPrev() {
    --this.cur < 1 && (this.cur = 1);
    this.switchQuestion();
  }
  onTapNext() {
    if (++this.cur > this.lens) {
      this.cur = this.lens;
    }
    this.switchQuestion();
  }
  // 点击切换题目
  onTapSwitch(index) {
    this.cur = index;
    this.switchQuestion();
    this.$refs.drop.toggle();
  }
  // 反馈
  onTapFeed({
    quizId,
    quizContent
  }) {
    this.$router.push(`/feed/${quizId}?q=${encodeURIComponent(quizContent)}`);
  }
  // 分享
  async onTapShare() {
    const {
      quizId,
      optionList,
      quizContent,
      quizChoiceType,
      quizDifficulty
    } = this.curr;
    const radio = quizChoiceType === 1;
    const cur = this.cur;
    const uid = cookies.get(USERID) || '';
    const uuid = cookies.get(USERUUID) || '';
    const paper = this.$route.query.paperid;
    const poster = await Share.createPoster({
      'qr': `${process.env.VUE_APP_API_POSTER + this.$route.fullPath}?share=1&idx=${cur}&uid=${uid}&uuid=${uuid}&paper=${paper}`,
      'num': quizId.toString(),
      'tag': OSSPOSTER + (radio ? '/radio.png' : '/check.png'),
      'star': `${OSSPOSTER}/star${quizDifficulty || '1'}.png`,
      'title': this.convertShareTitle(quizContent),
      'oa': OSSPOSTER + (radio ? '/rea.png' : '/cea.png'),
      'ob': OSSPOSTER + (radio ? '/reb.png' : '/ceb.png'),
      'oc': OSSPOSTER + (radio ? '/rec.png' : '/cec.png'),
      'od': OSSPOSTER + (radio ? '/red.png' : '/ced.png'),
      'va': this.convertShareOptionText(optionList[0].optionContent),
      'vb': this.convertShareOptionText(optionList[1].optionContent),
      'vc': this.convertShareOptionText(optionList[2].optionContent),
      'vd': this.convertShareOptionText(optionList[3].optionContent),
      'accessKey': '1HYtxzl7OeSB6T0c',
      'secretKey': 'PgdEyOlAHNL5Z6RF',
      'posterId': 616
    });
    dispatchAppFeature('share', {
      channel: [{
        type: 2,
        name: 'appmessage',
        src: poster // 分享图片 http 地址
      }, {
        type: 2,
        name: 'timeline',
        src: poster // 分享图片 http 地址
      }]
    });
  }
  // 处理分享的标题
  convertShareTitle(title) {
    const isImage = /<img[^>]+>/.test(title);
    const content = title.replace(/<[^>]+>/g, '').replace(/&[^;]+;/g, '').replace(/\n/g, '').replace(/\s+/g, ' ') || (isImage ? '无法显示图片，请扫码查看' : '扫码查看详情');
    return getMixinStrLens(content) > 108 ? substrMixinStr(content, 108) + '...' : content;
  }
  // 处理选项
  convertShareOptionText(txt) {
    // 获取字体中单字节总长度
    const single = getMixinStrLens(txt);
    return single / 2 > 15 ? substrMixinStr(txt, 29) + '...' : txt;
  }
  // 点击推荐试卷列表
  async onTapPaperRow(id) {
    this.$router.replace(`/paper/${id}`);
  }
};
__decorate([Getter('Analyze/list')], AnalyzeVue.prototype, "list", void 0);
__decorate([Getter('Analyze/lens')], AnalyzeVue.prototype, "lens", void 0);
__decorate([Getter('Analyze/data')], AnalyzeVue.prototype, "data", void 0);
__decorate([Getter('Paper/list')], AnalyzeVue.prototype, "rlist", void 0);
__decorate([Action('Analyze/getAnalyzeList')], AnalyzeVue.prototype, "getAnalyzeList", void 0);
__decorate([Action('Analyze/getAnalyzeByQuestion')], AnalyzeVue.prototype, "getAnalyzeByQuestion", void 0);
__decorate([Action('Paper/getRecommandPaper')], AnalyzeVue.prototype, "getRecommandPaper", void 0);
__decorate([Action('Analyze/getErrorRecordList')], AnalyzeVue.prototype, "getErrorRecordListApi", void 0);
AnalyzeVue = __decorate([Options({
  components: {
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Button.name]: Button,
    [Rate.name]: Rate,
    [Overlay.name]: Overlay,
    RecommandList
  }
})], AnalyzeVue);
export default AnalyzeVue;